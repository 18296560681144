import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  offerTitle: {
    margin: theme.spacing(1),
    marginBottom: 0
  },
  drawerContainer: {
    alignItems: "center",
    padding: theme.spacing(1),
  },
  drawerItems: {
    padding: theme.spacing(1),
  },

  btnControl: {
    margin: theme.spacing(1),
    float: "right"
  },
  selectError: {
    color: "#e70022",
    marginLeft: "10px",
    fontSize: ".85em",
    marginTop: "5px",
  },
  flexInput: { 
    flexGrow: "1"
  },
  footerContainer: {
  },  
  scrollableContainer: {
    maxHeight: 200,
    overflowY: 'auto',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  typeLabel: {
    width: 70,
    marginRight: 16,
  },
  textField: {
    width: '80%',
  },
  select: {
    width: '80%',
  },
  dateTimePicker: {
    width: '80%',
  },
}));
