import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Button,
  Divider,
  Typography,
  FormControl,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Title from "../../../Title";
import _ from "lodash";
import { useStyles } from "./styles";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import { TimePicker } from "@material-ui/pickers";
import { useCoreEntityContext } from "../../../../hooks/useCoreEntitySlice";
import useScheduledReportsOptions from "../../../../hooks/useScheduledReportsOptions/useScheduledReportsOptions";
import useDefaultOfDayWeek from "../../../../hooks/useDefaultOfDayWeek/useDefaultOfDayWeek";

async function isValidEmail(email) {
  try {
    await Yup.string().email().validate(email);
  } catch (error) {
    return false;
  }
  return true;
}

export const scheduledReportSchema = Yup.object().shape({
  reportid: Yup.number()
    .required("Required"),
  reporttypeid: Yup.number()
    .required("Required"),
  from: Yup.string()
    .required("Required"),
  reportperiodid: Yup.number()
    .required("Required"),
  daytosendid: Yup.number()
    .required("Required"),
  emails: Yup.string().trim()
    .test(
      'email',
      'Invalid email(s)',
      function (item) {
        let emails = item.replace(',', ';').replace(' ', '').split(';');
        let valid = true;
        emails.forEach(email => {
          valid = valid && isValidEmail(email);
        });
        return valid;
      })
    .required("Required"),
  exportformat: Yup.number()
    .required("Required"),
  timetosendData: Yup.string()
    .required("Required"),
});

const ScheduledReportsForm = ({
  handleSave,
  handleEdit,
  handleDelete,
  handleCancel,
  schedule,
  scheduledSettings,
}) => {
  const classes = useStyles();
  const { hasPermissions } = useHasPermissions();
  const useCoreEntitySlice = useCoreEntityContext();
  const { facilityID, userID } = useSelector((state) => {
    const facilityID = useCoreEntitySlice ? state.coreEntities?.ContextID : state.entities.ContextID;
    const userID = state.user.UserID;
    return { facilityID, userID }
  });

  const {
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...schedule,
    },
    resolver: yupResolver(scheduledReportSchema),
    mode: "onChange",
    shouldUnregister: false,
  });

  const { defaultreports, reporttypes, reportperiod, sendday, exportformat } =
    useScheduledReportsOptions();

  const { defaultOfDayWeek, fetchDefaultOfDayWeek } = useDefaultOfDayWeek(facilityID);

  useEffect(() => {
    fetchDefaultOfDayWeek();
  }, []);

  useEffect(() => {
    if (defaultOfDayWeek) {
      setValue("from", defaultOfDayWeek);
    }
  }, [defaultOfDayWeek, setValue]);

  const onSubmit = async (data) => {
    const sendData = {
      ...data,
      entityid: facilityID,
      userid: userID
    }
    if (schedule.scheduledrptid === undefined) {
      await handleSave(sendData);
    } else {
      await handleEdit(sendData);
    }
  };

  const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
  const handleTimePickerOpen = () => {
    setIsTimePickerOpen(true);
  };
  const handleTimePickerClose = () => {
    setIsTimePickerOpen(false);
  };

  const [isMenuItemOpen, setIsMenuItemOpen] = useState(false);
  const handleMenuItemOpen = () => {
    setIsMenuItemOpen(true);
  };
  const handleMenuItemClose = () => {
    setIsMenuItemOpen(false);
  };

  const selectedReportType = watch('reporttypeid');
  const selectedReportNameId = watch('reportid');
  const selectedTimeToSend = watch('timetosendData');

  const getReportPeriodOptions = (reportTypeId) => {
    switch (reportTypeId) {
      case 1:
        return {
          period: reportperiod.filter((period) => [1, 2].includes(period.reportPeriodId)),
          sendday: sendday.filter((sendday) => [1].includes(sendday.dayToSendId)),
          default: 2,
          message: 'Day report data will be a 24 hour time period based on the time set.',
        };
      case 2:
        return {
          period: reportperiod.filter((period) => [3, 4].includes(period.reportPeriodId)),
          sendday: sendday.filter((sendday) => [2, 3].includes(sendday.dayToSendId)),
          default: 4,
          message: 'Previous week will reflect data from the last 7 days, Sunday - Saturday, and current week will reflect data from Sunday through day the report was emailed.',
        };
      case 3:
        return {
          period: reportperiod.filter((period) => [5, 6].includes(period.reportPeriodId)),
          sendday: sendday.filter((sendday) => [4, 5].includes(sendday.dayToSendId)),
          default: 6,
          message: 'Previous month will reflect data of the previous month from the time chosen until the last day of that same time.  Current month will start on the first day of the current month from the time chosen through the current reportable day of the same time.',
        };
      default:
        return { period: [], default: null };
    }
  };

  const handleReportTypeChange = (e) => {
    const newReportType = e.target.value;
    const { default: defaultPeriod } = getReportPeriodOptions(newReportType);
    setValue('reporttypeid', newReportType);
    setValue('reportperiodid', defaultPeriod);
    setValue('daytosendid', undefined);
  };

  const isDuplicateReportNameId = (id) => {
    const count = scheduledSettings.filter(report => report.reportid === id).length;
    return count >= 5;
  };

  useEffect(() => {
    if (schedule.scheduledrptid) {
      setValue('timetosendData', `0001-01-01T${schedule.timetosend}:00`);
    }
  }, []);

  useEffect(() => {
    const formattedTimeToSend = formatTime(selectedTimeToSend);
    setValue("timetosend", formattedTimeToSend);
  }, [selectedTimeToSend]);

  const formatTime = (selectedTime) => {
    if (!selectedTime) return "";
    const time = new Date(selectedTime);
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  function ShowDateSection() {
    if ([2, 5, 19, 33].includes(selectedReportNameId)) {
      // Some reports like Accessholder status (2) etc. doesnot need the Report Date section.
      return false;
    }
    return true;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8} md={8}>
          <Typography className={clsx([classes.offerTitle])}>
            <Title>
              {schedule.scheduledrptid === undefined ? "Add Report" : "Edit Report"}
            </Title>
          </Typography>
        </Grid>
        {schedule.scheduledrptid && (
          <Grid item xs={4} md={4} className={clsx([classes.drawerContainer])}>
            <Button
              className={clsx("deleteBtn", classes.btnControl)}
              name="delete"
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              disabled={isSubmitting}
            >
              Delete
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider></Divider>
      <Grid container className={clsx([classes.drawerContainer])}>
        <Grid item xs={4} sm={4} className={clsx([classes.drawerItems])}>
          <Typography variant="h5">Report</Typography>
        </Grid>
        <Grid item xs={8} sm={8} className={clsx([classes.drawerItems])}>
          <Controller
            name="reportid"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth FormLabel="Report Name" size="small">
                <TextField
                  id="reportid"
                  label="Report Name"
                  select
                  {...field}
                  data-value={field?.value}
                  className={clsx("reportid", classes.reportid)}
                  variant="outlined"
                  error={!!errors.reportid}
                  helperText={errors.reportid && errors.reportid.message}
                  size="small"
                  data-testid="reportid"
                >
                  {defaultreports.filter((report) => hasPermissions([report.name], true)).map((name) => (
                    <MenuItem key={name.reportId} value={name.reportId}>
                      {name.displayName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
        {ShowDateSection() && (
          <Grid container>
            <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
              <Typography variant="h5">
                Report Date
                <Divider></Divider>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} className={clsx([classes.drawerItems])}>
              <Controller
                name="reporttypeid"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="Report Type" size="small">
                    <TextField
                      id="reporttypeid"
                      label="Report Type"
                      select
                      {...field}
                      data-value={field?.value}
                      className={clsx("reporttypeid", classes.reporttypeid)}
                      variant="outlined"
                      error={!!errors.reporttypeid}
                      helperText={errors.reporttypeid && errors.reporttypeid.message}
                      size="small"
                      data-testid="reporttypeid"
                      onChange={(e) => {
                        field.onChange(e);
                        handleReportTypeChange(e);
                      }}
                    >
                      {reporttypes.map((type) => (
                        <MenuItem key={type.reportTypeId} value={type.reportTypeId}>
                          {type.typeDescription}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={4} sm={4} className={clsx([classes.drawerItems])}>
              <Controller
                name="from"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth FormLabel="From" size="small">
                    <TimePicker
                      label={"From"}
                      name="from"
                      value={field?.value || null}
                      onChange={() => { }}
                      variant="outlined"
                      data-testid="from_time-picker"
                      size="small"
                      error={!!errors.from}
                      helperText={errors.from && errors.from.message}
                      disabled
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Tooltip
              title={getReportPeriodOptions(selectedReportType).message}
              arrow
              disableHoverListener={isMenuItemOpen}
            >
              <Grid item xs={4} sm={4} className={clsx([classes.drawerItems])}>
                <Controller
                  name="reportperiodid"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth FormLabel="Report Period" size="small">
                      <TextField
                        id="reportperiodid"
                        label="Report Period"
                        {...field}
                        data-value={field?.value}
                        className={clsx([classes.reportperiodid])}
                        variant="outlined"
                        select
                        error={!!errors.reportperiodid}
                        helperText={errors.reportperiodid && errors.reportperiodid.message}
                        size="small"
                        data-testid="reportperiodid"
                        onFocus={handleMenuItemOpen}
                        onBlur={handleMenuItemClose}
                      >
                        {getReportPeriodOptions(selectedReportType).period.map((period) => (
                          <MenuItem key={period.reportPeriodId} value={period.reportPeriodId}>
                            {period.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  )}
                />
              </Grid>
            </Tooltip>
          </Grid>
        )}

        <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
          <Typography variant="h5">
            When
            <Divider></Divider>
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} className={clsx([classes.drawerItems])}>
          <Controller
            name="daytosendid"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth FormLabel="Day to send" size="small">
                <TextField
                  id="daytosendid"
                  label="Day to send"
                  select
                  {...field}
                  data-value={field?.value}
                  className={clsx("daytosendid", classes.daytosendid)}
                  variant="outlined"
                  error={!!errors.daytosendid}
                  helperText={errors.daytosendid && errors.daytosendid.message}
                  size="small"
                >
                  {getReportPeriodOptions(selectedReportType).sendday.map((sendday) => (
                    <MenuItem key={sendday.dayToSendId} value={sendday.dayToSendId}>
                      {sendday.sendDescription}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
        <Tooltip
          title='This is the estimated time the report will be sent, pending resources.  If a report is needed prior to the time set, adjust the time accordingly.'
          arrow
          disableHoverListener={isTimePickerOpen}
        >
          <Grid item xs={4} sm={4} className={clsx([classes.drawerItems])}>
            <Controller
              name="timetosendData"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth FormLabel="At" size="small">
                  <TimePicker
                    label={"At"}
                    name="timetosendData"
                    data-testid="at_time-picker"
                    value={field?.value || null}
                    onChange={(timetosendData) => {
                      field.onChange(timetosendData);
                    }}
                    variant="outlined"
                    size="small"
                    error={!!errors.timetosendData}
                    helperText={errors.timetosendData && errors.timetosendData.message}
                    onOpen={handleTimePickerOpen}
                    onClose={handleTimePickerClose}
                  />
                </FormControl>
              )}
            />
          </Grid>
        </Tooltip>
        <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
          <Typography variant="h5">
            To Whom
            <Divider></Divider>
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} className={clsx([classes.drawerItems])}>
          <Typography>Email</Typography>
        </Grid>
        <Grid item xs={8} sm={8} className={clsx([classes.drawerItems])}>
          <Controller
            name="emails"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth FormLabel="Email Address" size="small">
                <TextField
                  {...field}
                  id="emails"
                  className={clsx("emails", classes.emails)}
                  label="Email Address"
                  variant="outlined"
                  error={!!errors.emails}
                  helperText={errors.emails && errors.emails.message}
                  size="small"
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} className={clsx([classes.drawerItems])}>
          <Typography>Format</Typography>
        </Grid>
        <Grid item xs={8} sm={8} className={clsx([classes.drawerItems])}>
          <Controller
            name="exportformat"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth FormLabel="Export Format" size="small">
                <TextField
                  id="exportformat"
                  label="Export Format"
                  select
                  {...field}
                  data-value={field?.value}
                  className={clsx("exportformat", classes.exportformat)}
                  variant="outlined"
                  error={!!errors.exportformat}
                  helperText={errors.exportformat && errors.exportformat.message}
                  size="small"
                  data-testid="exportformat"
                >
                  {exportformat.map((format) => (
                    <MenuItem
                      key={format.id}
                      id={format.id}
                      value={format.id}
                    >
                      {format.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
          <Typography variant="h5">
            Report Parameters
            <Divider></Divider>
          </Typography>
        </Grid>

      </Grid>
      <Divider></Divider>
      <Grid container className={clsx([classes.footerContainer])}>
        <Grid item xs={12} sm={12} className={clsx([classes.drawerItems])}>
          <Button
            className={clsx("cancelBtn", classes.btnControl)}
            name="cancel"
            variant="contained"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            Close
          </Button>
          <Tooltip
            title={
              (
                (selectedReportNameId !== schedule.reportid || schedule.scheduledrptid === undefined) &&
                isDuplicateReportNameId(selectedReportNameId)
              )
                ? 'Limited to only 5 of the same scheduled reports. Delete a scheduled report to add another.'
                : ''
            }
            arrow
          >
            <span style={{ float: "right" }}>
              <Button
                color="primary"
                name="submit"
                type="submit"
                variant="contained"
                data-testid="Save"
                className={clsx("saveBtn", classes.btnControl)}
                onClick={handleSubmit(onSubmit)}
                disabled={
                  isSubmitting ||
                  !selectedReportNameId ||
                  (
                    (selectedReportNameId !== schedule.reportid || schedule.scheduledrptid === undefined) &&
                    isDuplicateReportNameId(selectedReportNameId)
                  )
                }
              >
                Save
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};
export default ScheduledReportsForm;
