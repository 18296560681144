import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useScheduledReportsData } from "../../../providers/ScheduledReportsProvider";
import { onFiltersChanged } from "../../../reducers/scheduledReports/scheduledReportsReducer";
import ScheduledReportsService from "../../../services/ScheduledReportsService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { FindEntity } from "../../../state/slices/entities";
import ListView from "../../ListView/ListView";
import moment from "moment-timezone";
import { isEmpty } from "lodash";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";
import {selectContextEntity} from "../../../state/slices/CoreEntity";
import { LinearProgress } from "@material-ui/core";

const scheduledReportsService = new ScheduledReportsService(apiClient);

const ScheduledReportsList = ({ onSelect, onAddClick, refreshTrigger }) => {
  const useCoreEntitySlice = useCoreEntityContext();

  const {facilityID, tz} = useSelector((state)=>{
    const facilityID = useCoreEntitySlice ? state.coreEntities?.ContextID : state.entities.ContextID
    const tz = useCoreEntitySlice ? selectContextEntity(state).details?.timezone : FindEntity(state.entities?.EntityList ?? [], facilityID)?.details?.timezone;
    return { facilityID, tz }
  });
  const classes = useStyles();

  const { hasPermissions } = useHasPermissions();
  const [scheduledReports, setScheduledReports] = useState({
    display: []
  });
  const [scheduledSettings, setScheduledSettings] = useState({
    display: []
  });

  const [isLoading, setIsLoading] = useState(true);
  const { scheduledReportsData } = useScheduledReportsData();
  const [schldRprtsData, scheduledReportsDispatch] = scheduledReportsData;
  const enqueueSnackbar = useEnqueueSnackbar();

  const fillScheduledReportsList = useCallback(
    async (facilityID) => {
      let response;
      try {
        setIsLoading(true);
        response = await scheduledReportsService.getScheduledReports(
          facilityID
        );
      } catch (e) {
        setIsLoading(false);
        enqueueSnackbar("Failed to retrieve Scheduled Reports", {
          variant: "error",
          tag: "FailedToRetrieveScheduledReports"
        });
        return;
      }
      setIsLoading(false);
      setScheduledReports(response.data);
      formatScheduledReportsData(response.data);
      setScheduledSettings(response.data);
    },
    [
      facilityID
    ]
  );

  useEffect(() => {
    fillScheduledReportsList(
      facilityID
    );
  }, [
    facilityID, refreshTrigger
  ]);


  const formatScheduledReportsData = rawScheduledReports => {
    let mappingList = [];

    rawScheduledReports.map(scheduledReports => {
      mappingList.push({
        id: scheduledReports.scheduledrptid,
        report: scheduledReports.displayname,
        fromEmail: scheduledReports.email,
        "report period": scheduledReports.reporttype,
        when: scheduledReports.reportperiod,
        "to whom": scheduledReports.emails,
        "last run": formatDate(scheduledReports.reportlastrun),
        enabledSwitch: scheduledReports.enabled,
        reportName: scheduledReports.reportname
      });
    });

    setScheduledReports(prev => ({ ...prev, display: mappingList }));
  };

  const formatDate = (date) => {
    return isEmpty(date)
      ? ""
      : moment
          .utc(date)
          .tz(tz)
          ?.format("MM/DD/YYYY hh:mm:ss a");
  };

  const handleAddScheduledReports = () => {
    onAddClick(scheduledSettings);
  };

  const handleEditScheduledReports = (scheduledrptid) => {
    onSelect(scheduledSettings, scheduledrptid);
  };

  const handleSwitchToggle = async (scheduledrptid, enabled) => {
    try {
      const data = {
        scheduledrptid: scheduledrptid,
        enabled: enabled
      }
      const response = await scheduledReportsService.updateEnabledScheduledReport(data);
      if (response.status === 200 && response.data === true) {
        setScheduledReports(prevState => ({
          ...prevState,
          display: prevState.display.map(report =>
            report.id === scheduledrptid ? { ...report, enabledSwitch: enabled } : report
          )
        }));
      }
    } catch (ex) {
      enqueueSnackbar("Failed to update scheduled report", {
        variant: "error",
        tag: "FailedToUpdateScheduledRptsInfo"
      });
    }
  };

  const handlePageChange = (e, page) => {
    scheduledReportsDispatch({
      type: onFiltersChanged,
      payload: { scheduledReportsPage: page }
    });
  };

  return (
    <>
      <div className={classes.root}>
      {isLoading ? (
          <LinearProgress style={{ width: "100%", opacity: 0.5, marginTop: "2rem" }} data-testid="loading-progress" />
        ) : (
        <ListView
            handleAddButton={handleAddScheduledReports}
            handleEditButton={(id) => {handleEditScheduledReports(id);}}
            deleteButtonToggle={false}
            editButtonEnabledFunc={(row) => {return hasPermissions([row.reportName])}}
            showTableHead={true}
            titleBarTitleText="Scheduled Reports"
            titleBarToggle={true}
            titleBarTextToggle={true}
            paginationToggle={true}
            data={scheduledReports?.display}
            handlePageChange={handlePageChange}
            rowsToShowPerPage={[15, 25, 50]}
            rowsToShowDefault={15}
            currentPage={schldRprtsData.scheduledReportsPage ?? 1}
            isSwitchToggleEnabled = {true}
            handleSwitchToggle={handleSwitchToggle}
            sortCaseInsensitive={true}
            sortByID={true}
        />
        )}
      </div>
    </>
  );
};

ScheduledReportsList.defaultProps = {
  onSelect: () => {},
  onAddClick: () => {}
};

ScheduledReportsList.propTypes = {
  onSelect: PropTypes.func,
  onAddClick: PropTypes.func
};

export default ScheduledReportsList;
