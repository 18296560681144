import React from "react";
import _ from "lodash";
import ScheduledReportsForm from "./ReportForm"
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import apiClient from "../../../auth/apiClient";
import ScheduledReportsService from "../../../services/ScheduledReportsService";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";

const scheduledReportsService = new ScheduledReportsService(apiClient);

const ScheduledReportsComposer = ({ scheduledSettings, scheduledrptId, handleCancel, handleSave}) => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const defaultSchedule = {
    scheduledrptid: undefined,
    reportid: undefined,
    reporttypeid: 1,
    reportperiodid: 2,
    daytosend: undefined,
    timetosend: "",
    emails: "",
    exportFormat: undefined,
  }

  const { showDialog } = useConfirmationDialog();

  const formatSchedules = () => {
    const selectedSchedule = scheduledSettings?.filter(schedule => schedule.scheduledrptid === scheduledrptId);
    if (selectedSchedule && selectedSchedule.length) {
      return selectedSchedule[0];
    } else {
      return defaultSchedule;
    }
  };

  const addScheduledRpts = async data => {
    try {
      console.log(data);
      const result = await scheduledReportsService.createScheduledReport(data);
    } catch (ex) {
      enqueueSnackbar("Failed to create scheduled report", {
        variant: "error",
        tag: "FailedToCreateScheduledRptsInfo"
      });
    }
    handleSave();
  };

  const editScheduledRpts = async data => {
    try {
      const result = await scheduledReportsService.updateScheduledReport(data);
    } catch (ex) {
      enqueueSnackbar("Failed to update scheduled report", {
        variant: "error",
        tag: "FailedToUpdateScheduledRptsInfo"
      });
    }
    handleSave();
  };

  const deleteScheduledRpts = async () => {
    const currentSchedule = scheduledSettings?.filter(schedule => schedule.scheduledrptid === scheduledrptId)[0];
    const response = await showDialog({
      title: `Delete scheduled report`,
      message: "Are you sure you want to delete the scheduled report?",
      buttons: [
        { name: "Yes", color: "primary" },
        { name: "No", color: "secondary" }
      ]
    });
    if (response === "No") return;
    try {
      await scheduledReportsService.deleteScheduledReport(currentSchedule.scheduledrptid);
    } catch {
      enqueueSnackbar("Failed to delete scheduled report", {
        variant: "error",
        tag: "FailedToDeleteScheduledRptsInfo"
      });
    }
    handleSave();
  };

  return (
    <ScheduledReportsForm
    handleSave={addScheduledRpts}
    handleEdit={editScheduledRpts}
    handleDelete={deleteScheduledRpts}
    handleCancel={handleCancel}
    schedule={formatSchedules(scheduledSettings)}
    scheduledSettings={scheduledSettings}/>
  )
};
export default ScheduledReportsComposer;